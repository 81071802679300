import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaAngleLeft } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";

const variant = {
  show: {
    opacity: 1,
    scale: 1,
  },
  hidden: {
    opacity: 0,
    scale: 0.95,
  },
};

function Modal({ open, setOpen, layoutId, children }) {
  return (
    <>
      {open && (
        <motion.div
          className="modal-overlay"
          onClick={() => {
            setOpen(false);
          }}
        >
          <motion.div
            layoutId={layoutId}
            variants={variant}
            initial="hidden"
            animate="show"
            className="modal-inner-content"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="close">
              <AiFillCloseCircle
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
            <motion.div className="modal-mobile-actions">
              <motion.div
                className="back-button"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <FaAngleLeft />
              </motion.div>
            </motion.div>
            {children}
          </motion.div>
        </motion.div>
      )}
    </>
  );
}

Modal.defaultProps = {
  open: false,
  setOpen: () => {},
  layoutId: "modal-layoutId",
  children: <></>,
};

export default Modal;
