import React, { useState } from "react";
import { motion, AnimateSharedLayout } from "framer-motion";
import { zoomInWithDelay } from "../animations/variants";
import IntersectionBox from "../animations/IntersectionBox";
import TrailingParagraph from "../animations/TrailingParagraph";
import Modal from "./modal";
import ReactMarkdown from "react-markdown";

const cardVariant = {
  hover: {
    scale: 1.05,
  },
  tap: {
    scale: 0.95,
  },
};

const childVariant = {
  hover: {
    color: "#e65965",
  },
  hidden: {
    color: "#e65965",
  },
};

function getZoomIn(delay = 0) {
  return {
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        delay: delay,
        type: "spring",
        bounce: 0.6,
        damping: 20,
        mass: 0.5,
        velocity: 1.7,
      },
    },
    hidden: {
      opacity: 0,
      scale: 0.8,
    },
  };
}

function ProcessCard({
  title,
  visible_description,
  expanded_description,
  grades,
  img,
  icon,
  index,
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <motion.div
        className="expandable-card"
        variants={cardVariant}
        whileHover="hover"
        whileTap="tap"
        onClick={() => {
          setOpen(!open);
        }}
        style={{ height: "100%" }}
      >
        <div className="card-main">
          <figure>
            <img src={icon} alt={title} />
          </figure>
          <h2 className="card-head">{title} </h2>
          <p className="card-desc">{visible_description}</p>
          <motion.p
            variants={childVariant}
            initial="hidden"
            className="learn-more"
          >
            Learn More
          </motion.p>
        </div>
      </motion.div>

      <Modal open={open} setOpen={setOpen}>
        <div className="material-modal" style={{ flexDirection: "column" }}>
          <div
            className="image"
            style={{
              backgroundImage:
                `url(${img})` +
                ",linear-gradient(180deg, rgb(254, 254, 254) 0%, rgb(249, 249, 249) 100%)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              height: "300px",
              backgroundPosition: "center",
            }}
          >
            {/* <img src={img} /> */}
          </div>
          <div className="content">
            <div className="header">{title}</div>
            <div className="description">
              <ReactMarkdown>{visible_description}</ReactMarkdown>
              <ReactMarkdown>{expanded_description}</ReactMarkdown>
            </div>
            {grades.length > 0 && (
              <div className="grades">
                <ul>
                  {grades.map((grade, index) => (
                    <li>{grade}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

ProcessCard.defaultProps = {
  title: "Post Processing",
  visible_description: "Post Processing Description",
  expanded_description: "Post Processing Expanded Description",
  grades: [],
  img: "",
  index: 0,
  icon: "",
};

function ManufacturingSolutions({ title, description, processes }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  function shiftCardToCenter(index) {
    const elem = document.querySelector(
      ".manufacturing-solutions .card-container"
    );
    const child = elem.children[index];
    const childCenter = child.offsetLeft + child.offsetWidth / 2;
    const containerCenter = elem.parentElement.parentElement.offsetWidth / 2;
    console.log("Shifting", childCenter, containerCenter);
    const shift = childCenter - containerCenter;
    const shiftPx = `${shift}px`;
    // if (shift > 0) {
    //   elem.style.marginLeft = -`${shift}px`;
    // }
    elem.scrollLeft = shift;
    setCurrentIndex(index);
  }

  function shiftCardContainer(index) {
    const elem = document.querySelector(
      ".manufacturing-solutions .card-container"
    );
    const cardWidth = elem.firstChild.offsetWidth;
    const shift = `-${index * cardWidth}px`;
    elem.style.marginLeft = shift;
    setCurrentIndex(index);
  }

  return (
    <div className="manufacturing-solutions">
      <div className="content">
        <IntersectionBox>
          <h2 className="sol-head"> {title} </h2>
        </IntersectionBox>

        <p className="sol-desc">
          <TrailingParagraph paragraph={description} />
        </p>
      </div>
      <IntersectionBox>
        <div className="tabs-container">
          <div className="tabs">
            {processes.map((process, index) => (
              <a
                className={`tab ${index === currentIndex ? "selected" : ""}`}
                href={`#${index}`}
                onClick={() => {
                  shiftCardToCenter(index);
                }}
              >
                {process.service_name}
              </a>
            ))}
          </div>
        </div>
      </IntersectionBox>

      <motion.div className="card-container">
        {processes.map((subProcess, index) => (
          <motion.div
            variants={getZoomIn(1 * index)}
            initial="hidden"
            animate="show"
            style={{ height: "100%" }}
          >
            <ProcessCard
              title={subProcess.service_name}
              expanded_description={subProcess.expanded_text}
              visible_description={subProcess.visible_description}
              index={index}
              icon={subProcess.icon?.image?.url}
              img={subProcess.popup_image?.url}
            />
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
}

ManufacturingSolutions.defaultProps = {
  title: "End to end manufacturing solutions",
  description: "Manufacturing solutions description",
  processes: [
    {
      service_name: "Service Name",
      visible_description: "Visible Description",
      expanded_text: "Expanded Text",
    },
    {
      service_name: "Service Name",
      visible_description: "Visible Description",
      expanded_text: "Expanded Text",
    },
    {
      service_name: "Service Name",
      visible_description: "Visible Description",
      expanded_text: "Expanded Text",
    },
    {
      service_name: "Service Name",
      visible_description: "Visible Description",
      expanded_text: "Expanded Text",
    },
    {
      service_name: "Service Name",
      visible_description: "Visible Description",
      expanded_text: "Expanded Text",
    },
    {
      service_name: "Service Name",
      visible_description: "Visible Description",
      expanded_text: "Expanded Text",
    },
  ],
};

export default ManufacturingSolutions;
